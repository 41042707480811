import { useDispatch, useSelector } from 'react-redux'
import { List, Skeleton, Avatar } from 'antd'
import { MinusOutlined } from '@ant-design/icons'
import products, { productsSlice } from '../redux/slices/products'

export const Cart = () => {
  const cartProducts = useSelector((state) => state.products.cartProducts)
  const dispatch = useDispatch()

  const handleDeleteCart = (item) => {
    dispatch(productsSlice.actions.removeProduct(item))
  }

  return (
    <List
      className="demo-loadmore-list"
      itemLayout="horizontal"
      dataSource={cartProducts}
      style={{ overflowY: 'auto', maxHeight: 400 }}
      renderItem={(item) => (
        <List.Item>
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={<Avatar src={item.image_url} />}
              title={<div>{item.title}</div>}
              description={
                <div>
                  Size : {item.size}
                  <div>Category : {item.categorie}</div>
                </div>
              }
            />
            <MinusOutlined
              onClick={(e) => handleDeleteCart(item)}
              style={{
                fontSize: '20px',
                color: 'blue',
              }}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  )
}
