import React, { useEffect, useState } from 'react'
import { Select, Row, Col, Tooltip, message } from 'antd'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../constant'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import { productsSlice } from '../redux/slices/products'

const ProductDetail = () => {
  const { productId } = useParams()
  const dispatch = useDispatch()

  const width = useSelector((state) => state.products.width)
  const [product, setProduct] = useState()
  const [clickedImg, setClickedImg] = useState('')
  const [sizeItems, setSizeItems] = useState([])

  const [size, setSize] = useState()

  const handleAddCart = (e) => {
    e.stopPropagation()
    if (!(product?.sizes.length > 0)) {
      dispatch(productsSlice.actions.addProduct({ ...product, size }))
    } else {
      if (size) dispatch(productsSlice.actions.addProduct({ ...product, size }))
      else message.error('Please select a size!')
    }
  }

  useEffect(() => {
    axios.get(BASE_URL + `product?productId=${productId}`).then((result) => {
      setProduct(result.data)
    })
  }, [productId])

  useEffect(() => {
    if (product) {
      setClickedImg('')
      setSizeItems(
        product?.sizes?.map((size, index) => ({ label: size, value: size }))
      )
      if (product?.sizes.length > 0)
        setSize(
          product?.sizes?.map((size, index) => ({
            label: size,
            value: size,
          }))[0].value
        )
      else setSize('STANDART')
    }
  }, [product])

  function imageClick(e) {
    setClickedImg(e.target.src)
  }
  return (
    <>
      {product ? (
        <Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '3%',
            }}
          >
            <p style={{ fontSize: '1rem' }}>{product.title}</p>
            <Col
              xs={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <img
                alt="example"
                src={
                  clickedImg.length === 0 ? product.imagesUrls[0] : clickedImg
                }
                style={{ width: width < 700 ? '100%' : '50%' }}
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {product?.sizes.length > 0 ? (
                <Select
                  onChange={(e) => setSize(e)}
                  placeholder="Select a size"
                  defaultValue={product?.sizes ? product?.sizes[0] : null}
                  style={{ width: 120, marginLeft: '5%' }}
                  options={sizeItems}
                />
              ) : null}
              <Tooltip title="Add to Cart">
                <PlusOutlined
                  onClick={handleAddCart}
                  style={{
                    fontSize: '20px',
                    color: 'blue',
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Col xs={24} style={{ display: 'flex', flexDirection: 'row' }}>
              {product.imagesUrls.map((elem) => (
                <div style={{ width: '20%' }}>
                  <img
                    alt="example"
                    src={elem}
                    style={{
                      width: '100%',
                      border: 'solid',
                      borderColor: 'black',
                      padding: '5%',
                    }}
                    onClick={(e) => imageClick(e)}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </Row>
      ) : (
        'Loading....'
      )}
    </>
  )
}
export default ProductDetail
