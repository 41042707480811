import { Checkbox, List, Button, Popover } from 'antd'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'
import ProductCard from '../components/ProductCard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../constant'
import { useSelector } from 'react-redux'

const fuseOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  // threshold: 0.6,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ['title'],
}

function Products() {
  const { t } = useTranslation()
  const { categorie } = useParams()

  const searchedKey = useSelector((state) => state.products.searchedKey)
  const [initLoading, setInitLoading] = useState(true)
  const [loadMoreSize, setLoadMoreSize] = useState(20)
  const [sizes, setSizes] = useState([])
  const [selectedSizes, setSelectedSizes] = useState(sizes)
  const [list, setList] = useState([])
  const width = useSelector((state) => state.products.width)
  const fuse = new Fuse(list, fuseOptions)

  const searchedData = useMemo(() => {
    if (searchedKey)
      return fuse
        .search(searchedKey)
        .map((e) => e.item)
        .slice(0, loadMoreSize)
    return list.slice(0, loadMoreSize)
  }, [searchedKey, list, loadMoreSize])

  useEffect(() => {
    axios(BASE_URL + `sizes?categorie=${categorie}`).then(({ data }) => {
      setSizes(data)
    })
  }, [categorie])

  useEffect(() => {
    const fetchContent = async () => {
      setInitLoading(true)
      await axios(
        BASE_URL +
          `items/?categorie=${categorie}&sizes=${selectedSizes.toString()}`
      ).then(({ data }) => {
        setInitLoading(false)
        setList(data)
      })
    }
    fetchContent()
  }, [categorie, selectedSizes])

  const onChange = (checkedValues) => {
    setSelectedSizes(checkedValues)
  }

  const onLoadMore = () => {
    setLoadMoreSize(loadMoreSize + 20)
  }

  const loadMore = !initLoading
    ? searchedData.length < list.length && (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={onLoadMore}>loading more</Button>
        </div>
      )
    : null

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            margin: width < 700 ? '10px' : 0,
            fontSize: '1.5rem',
            marginBottom: '0.5rem',
          }}
        >
          {t(categorie)}
        </div>
        {sizes.length > 0 && (
          <div>
            <Popover
              placement="bottom"
              content={
                <Checkbox.Group
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  options={sizes}
                  value={selectedSizes}
                  onChange={onChange}
                />
              }
            >
              <Button style={{ backgroundColor: '#db6f07' }} type="primary">
                Sizes
              </Button>
            </Popover>
          </div>
        )}
      </div>
      <List
        grid={{
          xs: 2,
          sm: 3,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        loading={initLoading}
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={searchedData}
        renderItem={(item) => <ProductCard product={item} />}
        footer={loadMore}
      />
    </div>
  )
}

export default Products
