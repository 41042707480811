import { Badge, Col, Layout, Menu, Modal, Row, Spin } from 'antd'
import data from '../data/data.json'
import { useEffect, useState } from 'react'
import { groupBy, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { COLORS } from '../values/colors'
import {
  ShoppingCartOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
} from '@ant-design/icons'
import Search from 'antd/es/input/Search'
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../constant'
import axios from 'axios'
import { Cart } from '../components/Cart'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../redux/store'
import { productsSlice } from '../redux/slices/products'
import LogoSvg from '../assets/appLogo.svg'
const { Header, Content, Sider } = Layout

const CustomSearch = styled(Search)`
  justify-content: center;
  width: 50%;
  && .ant-input-search-button {
    background-color: ${COLORS.orange};
  }
  && .ant-input-search-button:hover {
    background-color: ${COLORS.orange};
  }
  && .ant-input-outlined {
    border-color: ${COLORS.lightGray};
  }
`

function Home() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(false)
  const [categories, setCategories] = useState([])
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [selectedKeys, setSelectedKeys] = useState('All items')

  const lengthOfProduct = useSelector(
    (state) => state.products.cartProducts.length
  )

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    dispatch(productsSlice.actions.setHeight(window.innerHeight))
    dispatch(productsSlice.actions.setWidth(window.innerWidth))
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const [openCartModal, setOpenCartModal] = useState(false)
  useEffect(() => {
    axios(BASE_URL + 'categories').then((result) => {
      setCategories(result.data)
    })
  }, [])

  return (
    <Layout hasSider>
      <Modal
        title="Shopping Cart"
        open={openCartModal}
        onCancel={() => setOpenCartModal(false)}
        onOk={() => setOpenCartModal(false)}
      >
        <Cart />
      </Modal>

      <Sider
        onCollapse={(value) => setCollapsed(value)}
        collapsed={collapsed}
        breakpoint="lg"
        style={{
          backgroundColor: `${COLORS.white}`,
          overflowX: 'scroll',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          className="demo-logo-vertical"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/')}
        >
          <img
            src={require('../assets/appLogo.png')}
            alt="Logo SVG"
            style={{ width: width > 890 ? '50%' : '100%' }}
          />
          {width > 890 && (
            <img
              src="https://fontmeme.com/permalink/240205/f0dd2afe8958cdd5501c866f9d6929e2.png"
              alt="grand-theft-auto-font"
              border="0"
              style={{ width: '50%' }}
            />
          )}
        </div>

        {categories.length > 0 && (
          <Menu
            defaultSelectedKeys={'All items'}
            selectedKeys={selectedKeys}
            onSelect={(value) => setSelectedKeys(value.key)}
            items={[
              {
                key: 'All items',
                label: <Link to="/all-items">{'All items'}</Link>,
                icon: collapsed ? <div>A</div> : null,
              },
              ...categories.map((e, index) => ({
                key: e,
                label: <Link to={`/${e}`}>{t(e)}</Link>,
                icon: collapsed ? <div>{t(e)[0]}</div> : null,
              })),
            ]}
          />
        )}
      </Sider>

      <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
        <Header
          style={{
            padding: '0 30px',
            backgroundColor: 'white',
            borderBottom: 'solid',
            borderBottomColor: `${COLORS.lightGray}`,
            position: 'relative',
          }}
        >
          <Row
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {width >= height && (
              <Col
                xs={4}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {!collapsed ? (
                  <LeftCircleTwoTone
                    style={{ fontSize: '24px' }}
                    twoToneColor="#db6f07"
                    onClick={() => setCollapsed(true)}
                  />
                ) : (
                  <RightCircleTwoTone
                    twoToneColor="#db6f07"
                    style={{ fontSize: '24px' }}
                    onClick={() => setCollapsed(false)}
                  />
                )}
              </Col>
            )}

            <Col
              xs={16}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomSearch
                onSearch={(value) => {
                  if (value) {
                    dispatch(productsSlice.actions.setSearchedKey(value))
                    navigate('/')
                    setSelectedKeys('All items')
                  }
                }}
                placeholder="Search products..."
                enterButton
                style={{ width: width < height && '100%' }}
              />
            </Col>
            <Col
              xs={{ span: 4 }}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Badge count={lengthOfProduct}>
                <ShoppingCartOutlined
                  onClick={() => setOpenCartModal(true)}
                  style={{ color: `${COLORS.orange}`, fontSize: '200%' }}
                />
              </Badge>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            padding: width > 700 ? '20px 50px 40px ' : 0,
            minHeight: 280,
            overflow: 'auto',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Home
