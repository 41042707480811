import React, { useState } from 'react'
import { Card, Select, Tooltip, message } from 'antd'

import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { productsSlice } from '../redux/slices/products'

const { Meta } = Card
const ProductCard = ({ product }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const width = useSelector((state) => state.products.width)
  const height = useSelector((state) => state.products.height)

  const [size, setSize] = useState()

  function onClick() {
    navigate(`${product.link}`)
  }

  const handleAddCart = (e) => {
    e.stopPropagation()
    if (!(product?.sizes.length > 0)) {
      dispatch(productsSlice.actions.addProduct({ ...product, size }))
    } else {
      if (size) dispatch(productsSlice.actions.addProduct({ ...product, size }))
      else message.error('Please select a size!')
    }
  }

  return (
    <Card
      onClick={onClick}
      hoverable
      style={{
        width: '100%',
        margin: '5%',
      }}
      title={
        product?.sizes.length > 0 ? (
          <div onClick={(e) => e.stopPropagation()}>
            <Select
              placeholder="Select a size"
              style={{ width: width < 700 ? '100%' : '50%' }}
              onChange={(e) => setSize(e)}
            >
              {product?.sizes?.map((e) => {
                return <Select.Option value={e}>{e}</Select.Option>
              })}
            </Select>
          </div>
        ) : null
      }
      actions={[
        <Tooltip title="Add to Cart">
          <PlusOutlined
            onClick={handleAddCart}
            style={{
              width: '100%',
              fontSize: '20px',
              color: 'blue',
            }}
          />
        </Tooltip>,
      ]}
      cover={
        <img
          alt="example"
          src={product.image_url}
          style={{ padding: width < 700 ? 0 : '5%' }}
        />
      }
    >
      <Meta title={product.title} />
    </Card>
  )
}
export default ProductCard
