import { createSlice } from '@reduxjs/toolkit'

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    cartProducts: [],
    searchedKey: '',
    width: window.innerWidth,
    height: window.innerHeight,
  },
  reducers: {
    addProduct: (state, action) => {
      state.cartProducts = [...state.cartProducts, action.payload]
    },
    removeProduct: (state, action) => {
      const index = state.cartProducts.findIndex(
        (e) => e.id === action.payload.id
      )
      state.cartProducts = state.cartProducts.filter((e, i) => index !== i)
    },
    setSearchedKey: (state, action) => {
      state.searchedKey = action.payload
    },
    setWidth: (state, action) => {
      state.width = action.payload
    },
    setHeight: (state, action) => {
      state.height = action.payload
    },
  },
})

export const { addProduct, removeProduct } = productsSlice.actions

export default productsSlice.reducer
