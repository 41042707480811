import './App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Home from './layout/Home'
import Products from './screens/Products'
import ProductDetail from './screens/ProductDetail'

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Home />}>
          <Route path="/" element={<Navigate to="/all-items" />} />
          <Route path="/:categorie" element={<Products />}></Route>
          <Route path="/:categorie/:productId" element={<ProductDetail />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
